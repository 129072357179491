import { GetComponentProps } from 'rc-table/lib/interface'
import React from 'react'
import { ServiceInterface } from '../../../Interface/ShipmentInterface'
// import Services from '../../../components/Services'

interface RowExtendedProps extends GetComponentProps<any> {
	services: any
	index: number
	className: string
	rowClassesByIdx: string
	children: any
}
const RowExtended: React.FC<RowExtendedProps> = (record) => {
	// const rowClassesByIdx = record.index % 2 === 0 ? 'row-dark' : 'row-light'
	return (
		<>
			<tr className={`${record.rowClassesByIdx}  data-row cursor-pointer ant-table-row`}>{record.children}</tr>
			{record?.services?.length && (
				<tr className={`${record.rowClassesByIdx}  data-row cursor-pointer ant-table-row data-row_extended`}>
					<td colSpan={6} className='row-extended'>
						<div className='flex items-center pd-bt-15'>
							<span className={'font-size-14 txt-color-view mg-r-4 mg-bt-4 whitespace'}>
								{/* {t('orders.services')}: */}
								Dịch vụ:
							</span>
							{record?.services?.length > 0
								? record?.services?.map((item: ServiceInterface, idx: number) => {
										let colorService = ''
										if (item.needApprove) {
											if (item.approved === null || item.approved === undefined) {
												colorService = 'services-pending'
											} else if (item.approved === false) {
												colorService = 'services-reject'
											}
										}
										return (
											<span className='items-center' key={idx}>
												<span
													className={`mg-r-4 ${colorService}`}
												>{`${item.name}`}</span>
												{idx !== record?.services?.length - 1 && (
													<span className='mg-r-4'>|</span>
												)}
											</span>
										)
								  })
								: '---'}
						</div>
					</td>
				</tr>
			)}
			<tr className='h-20'></tr>
		</>
	)
}

export default RowExtended

