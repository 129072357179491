import { Avatar, Grid, List, Table, Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import lodash from 'lodash'
import React from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import EmptyData from '../../../Component/EmptyData'
import SkeletonTable from '../../../Component/SkeletonTable'
import ProviderInterface from '../../../Interface/ProviderInterface'
import StatusInterface from '../../../Interface/StatusInterface'
import { trans } from '../../../resources/localization/Localization'
import { formatDateTime, formatDateTime1, formatMoneyByUnit, getCleanFormFilter } from '../../../Util/string'
import { Row } from 'antd'
import { Col } from 'antd'
import HeaderList from './HeaderList'
import { DATA_TABLE_LAYOUT, DATA_TABLE_LIST } from '../../../Util/Constants'
import { Pagination } from 'antd'
import { ShipmentsCriteria } from '../../../Criteria/ShipmentsCriteria'
import ShipmentInterface, { ServiceInterface } from '../../../Interface/ShipmentInterface'
import { Divider } from 'antd'
import useWindowDimensions from '../../../Util/dimention'
const { useBreakpoint } = Grid
interface ListProps {
	total: number
	// 	page: number
	// 	size: number
	data: Array<ShipmentInterface>
	providers: Array<ProviderInterface>
	statuses?: Array<StatusInterface>
	typeDataTable: string
	handleTypeTableData: (val: string) => void
	// 	filter: OrdersCriteria
	// 	loading: boolean
	// 	handleFilter: (filter: OrdersCriteria) => void
	// 	setPage: (val: number) => void
	// 	setPageSize: (val: number) => void
}
const ShipmentList: React.FC<ListProps> = (props) => {
	// const { xl, md } = useBreakpoint()
	const { width } = useWindowDimensions()
	const {
		data,
		typeDataTable,
		providers,
		statuses,
		//    page,
		//     size,
		total,
		//   filter,
		//    handleFilter,
		//     setPage,
		// 	 setPageSize,
		// 	  loading
		handleTypeTableData,
	} = props
	const navigate = useNavigate()
	// console.log('useWindowDimensions',height,width);

	return (
		<>
			<List
				className='shipments_items pd-16'
				pagination={false}
				header={
					<HeaderList
						total={total}
						typeDataTable={typeDataTable}
						setTypeDataTable={(val: string) => handleTypeTableData(val)}
					/>
				}
				dataSource={data}
				renderItem={(item: ShipmentInterface, idx) => {
					const statusObj = statuses?.find(({ code }: StatusInterface) => code === item.status)
					const provider = providers.find((prod: ProviderInterface) => prod.code === item.provider)
					const services = lodash.get(item, 'services', [])
					return width > 1024 ? (
						<List.Item
							key={item.code}
							onClick={() => navigate(`/shipments/${item.code}`)}
							className={`cursor-pointer ${idx % 2 === 0 ? 'row-dark' : 'row-light'}`}
						>
							<List.Item.Meta
								avatar={
									<Avatar
										src={item.image ? item?.image : ''}
										shape='square'
										size={80}
										className='bd-none-impt rad-6'
									/>
								}
								title={
									<Row align='middle' gutter={[16, 16]}>
										<Col span={8}>
											<Row align='middle' className='mg-bt-6'>
												<Link to={`/shipments/${item.code}`} >
													<Tooltip
														color='#CD5E77'
														title={
															<span className=''>
																Mã đơn hàng
															</span>
														}
													>
														<Paragraph
															className='robotomedium mg-0 txt-color-view fsz-14 line-h-22 whitespace'
															copyable={{
																text: item.code,
																icon: [
																	<Tooltip
																		title={
																			<span className=''>
																				Copy
																			</span>
																		}
																		color='#CD5E77'
																	>
																		<i className='far fa-copy txt-color-black line-h-14 w-12'></i>
																	</Tooltip>,
																	<Tooltip
																		title={
																			<span className=''>
																				Copied
																			</span>
																		}
																		color='#CD5E77'
																	>
																		<i className='far fa-check fsz-12 line-h-14 w-12'></i>
																	</Tooltip>,
																],
																tooltips: [false, false],
															}}
														>
															{`#${item.code}`}
														</Paragraph>
													</Tooltip>
												</Link>
												<span className='flex align-items-center '>
													<Tooltip
														color='#CD5E77'
														title={
															<span className=''>
																Mua/Nhận
															</span>
														}
													>
														<span className='mg-r-3 fsz-14 line-h-22'>{`${
															item.orderedQuantity ? item.orderedQuantity : '---'
														} / ${
															item.receivedQuantity ? item.receivedQuantity : '---'
														}`}</span>
													</Tooltip>
												</span>
											</Row>

											<Row align='middle' className='mg-bt-6'>
												<Tooltip
												color='#CD5E77'
												title={
													<span className=''>
														Người bán
													</span>
												}
												>
												<div className={'dpl-flex align-items-center fsz-14 line-h-22'}>
													<Avatar
														size={20}
														src={provider?.logo}
														shape={'circle'}
														className='bd-none-impt mg-r-4'
													/>
													<span className='txt-color-black fsz-14 line-h-22'>
														{provider?.code}
													</span>
													<Divider
														type={'vertical'}
														className={'mx-10 bd-color-black h-16 line-h-22 mx-4'}
													/>
													<span className={'robotoregular fsz-14 line-h-22'}>
														{provider?.name}
													</span>
												</div>
												</Tooltip>
											</Row>

											<Row align='middle'>
												<span className='fsz-14 line-h-22'>
													<Tooltip
														color='#CD5E77'
														title={
															<span className=''>
																{/* {t('shipments.createAtTooltip')} */}
																Thời gian tạo
															</span>
														}
													>
														<span className='fsz-14 line-h-22 txt-color-gray'>
															{item.timestamp ? formatDateTime1(item.timestamp) : '---'}{' '}
														</span>
													</Tooltip>
												</span>
											</Row>
										</Col>

										<Col span={10}>
											<Row align='middle' className='mg-bt-6'>
												{item.services.length > 0 && (
													<div className='mg-r-18 flex'>
														<span className='txt-color-gray fsz-14 line-h-20 whitespace mg-r-4'>
															{/* {`${t('shipments.services')}:`} */}
															Dịch vụ:{' '}
														</span>
														{services.length > 0
															? services.map((item: ServiceInterface, idx: number) => {
																	let colorService = ''
																	if (item.needApprove) {
																		if (
																			item.approved === null ||
																			item.approved === undefined
																		) {
																			colorService = 'services-pending'
																		} else if (item.approved === false) {
																			colorService = 'services-reject'
																		}
																	}
																	return (
																		<span className='items-center' key={idx}>
																			<span
																				className={`mg-r-4 text text-nowrap line-h-20 ${colorService}`}
																			>{`${item.name}`}</span>
																			{idx !== services.length - 1 && (
																				<span className='mg-r-4'>|</span>
																			)}
																		</span>
																	)
															  })
															: '---'}
													</div>
												)}
											</Row>
											<Row align='middle' className='mg-bt-6'>
												<span className='mg-r-4 txt-color-gray'>Cần thanh toán: </span>
												<span>
													{item.totalUnpaid ? formatMoneyByUnit(item.totalUnpaid) : '---'}
												</span>
											</Row>

											<Row align='middle'>
												<span className='mg-r-4 txt-color-gray'>Khách hàng: </span>
												<span> {item.username ? item.username : '---'}</span>
											</Row>
										</Col>
										<Col span={6}>
											<div className='shipment-status flr'>
												<span
													className='line-h-22 rad-25 txt-color-white px-16 py-10 txt-capitalize fsz-14  robotomedium '
													style={{ backgroundColor: `${statusObj?.color}` }}
												>
													{statusObj?.name}
												</span>
											</div>
										</Col>
									</Row>
								}
							/>
						</List.Item>
					) : width > 767 ? (
						<>
							<List.Item
								key={item.code}
								onClick={() => navigate(`/shipments/${item.code}`)}
								className={`cursor-pointer`}
							>
								<List.Item.Meta
									avatar={
										<Avatar
											src={item.image ? item?.image : ''}
											shape='square'
											size={56}
											className='bd-none-impt rad-6'
										/>
									}
									title={
										<Row align='middle' >
											<Col span={6}>
												<Row align='middle' className='mg-bt-12'>
													<Link to={`/shipments/${item.code}`} >
														<Paragraph
															className='robotomedium mg-0 txt-color-view fsz-14 line-h-22 whitespace'
															copyable={{
																text: item.code,
																icon: [
																	<Tooltip
																		title={
																			<span className=''>
																				Copy
																			</span>
																		}
																		color='#CD5E77'
																	>
																		<i className='far fa-copy txt-color-black line-h-14 w-12'></i>
																	</Tooltip>,
																	<Tooltip
																		title={
																			<span className=''>
																				Copied
																			</span>
																		}
																		color='#CD5E77'
																	>
																		<i className='far fa-check fsz-12 line-h-14 w-12'></i>
																	</Tooltip>,
																],
																tooltips: [false, false],
															}}
														>
															{`#${item.code}`}
														</Paragraph>
													</Link>
												</Row>
												<Row>
													<span className='flex align-items-center '>
														<Tooltip
															color='#CD5E77'
															title={
																<span className=''>
																	{/* {t('shipments.quantifyTooltip')} */}
																</span>
															}
														>
															<span className='mg-r-3 fsz-14 line-h-22'>{`${
																item.orderedQuantity ? item.orderedQuantity : '---'
															} / ${
																item.receivedQuantity ? item.receivedQuantity : '---'
															}`}</span>
														</Tooltip>
													</span>
												</Row>
											</Col>

											<Col span={8}>
												<Row align='middle' className='mg-bt-12'>
													<div className={'dpl-flex align-items-center fsz-14 line-h-22'}>
														<Avatar
															size={20}
															src={provider?.logo}
															shape={'circle'}
															className='bd-none-impt mg-r-4'
														/>
														<span className='txt-color-black fsz-14 line-h-22'>
															{provider?.code}
														</span>
														<Divider
															type={'vertical'}
															className={'mx-10 bd-color-black h-16 line-h-22 mx-4'}
														/>
														<span className={'robotoregular fsz-14 line-h-22'}>
															{provider?.name}
														</span>
													</div>
												</Row>

												<Row align='middle'>
													<span className='mg-r-4 txt-color-gray'>Khách hàng: </span>
													<span> {item.username ? item.username : '---'}</span>
												</Row>
											</Col>

											<Col span={5}>
												<Row align='middle' className='mg-bt-6'>
													<span className='mg-r-4 txt-color-gray'>Cần thanh toán: </span>
													<span>
														{item.totalUnpaid ? formatMoneyByUnit(item.totalUnpaid) : '---'}
													</span>
												</Row>
											</Col>

											<Col span={5} className='grid grid-items-end'>
												<Row>
													<div className='shipment-status mg-bt-12'>
														<span
															className='line-h-22 rad-25 txt-color-white px-10 py-5 txt-capitalize fsz-14  robotomedium '
															style={{ backgroundColor: `${statusObj?.color}` }}
														>
															{statusObj?.name}
														</span>
													</div>
												</Row>

												<Row>
													<div>
														<span className='fsz-14 line-h-22'>
															<Tooltip
																color='#CD5E77'
																title={
																	<span className='robotoregular fsz-12 txt-color-white line-h-20'>
																		{/* {t('shipments.createAtTooltip')} */}
																	</span>
																}
															>
																<span className='fsz-14 line-h-22 txt-color-gray'>
																	{item.timestamp
																		? formatDateTime1(item.timestamp)
																		: '---'}{' '}
																</span>
															</Tooltip>
														</span>
													</div>
												</Row>
											</Col>
										</Row>
									}
								/>
							</List.Item>
							<Row align='middle' className='mg-bt-20 bg-color-pink px-20 py-10 rad-8'>
								{item.services.length > 0 && (
									<div className='mg-r-18 flex'>
										<span className='txt-color-gray fsz-14 line-h-20 whitespace mg-r-4'>
											{/* {`${t('shipments.services')}:`} */}
											Dịch vụ:{' '}
										</span>
										{services.length > 0
											? services.map((item: ServiceInterface, idx: number) => {
													let colorService = ''
													if (item.needApprove) {
														if (item.approved === null || item.approved === undefined) {
															colorService = 'services-pending'
														} else if (item.approved === false) {
															colorService = 'services-reject'
														}
													}
													return (
														<span className='items-center' key={idx}>
															<span
																className={`mg-r-4 text text-nowrap line-h-20 ${colorService}`}
															>{`${item.name}`}</span>
															{idx !== services.length - 1 && (
																<span className='mg-r-4'>|</span>
															)}
														</span>
													)
											  })
											: '---'}
									</div>
								)}
							</Row>
							<div className='end-row' />
						</>
					) : (
						<List.Item
							key={item.code}
							onClick={() => navigate(`/shipments/${item.code}`)}
							className={`cursor-pointer ${idx % 2 === 0 ? 'row-dark' : 'row-light'}`}
						>
							<List.Item.Meta
								// avatar={}
								title={
									<>
										<div className='flex justify-between'>
											<Col className='flex'>
												<Avatar
													src={item.image ? item?.image : ''}
													shape='square'
													size={56}
													className='bd-none-impt rad-6'
												/>
												<div className='mg-l-10'>
													<Link to={`/shipments/${item.code}`} >
														<Paragraph
															className='robotomedium mg-0 txt-color-view fsz-14 line-h-22 whitespace'
															copyable={{
																text: item.code,
																icon: [
																	<Tooltip
																		title={
																			<span className=''>
																				Copy
																			</span>
																		}
																		color='#CD5E77'
																	>
																		<i className='far fa-copy txt-color-black line-h-14 w-12'></i>
																	</Tooltip>,
																	<Tooltip
																		title={
																			<span className=''>
																				Copied
																			</span>
																		}
																		color='#CD5E77'
																	>
																		<i className='far fa-check fsz-12 line-h-14 w-12'></i>
																	</Tooltip>,
																],
																tooltips: [false, false],
															}}
														>
															{`#${item.code}`}
														</Paragraph>
													</Link>

													<span
														className='fsz-14 line-h-22'
													>{`${item.orderedQuantity ? item.orderedQuantity : '---'} / ${
														item.receivedQuantity ? item.receivedQuantity : '---'
													}`}</span>
												</div>
											</Col>
											<Col>
												{/* <div className='shipment-status '> */}
												<span
													className='line-h-22 rad-25 txt-color-white px-10 py-5 txt-capitalize fsz-14  robotomedium '
													style={{ backgroundColor: `${statusObj?.color}` }}
												>
													{statusObj?.name}
												</span>
												{/* </div> */}
											</Col>
										</div>
										<div className='justify-start mg-t-12'>
											<span className='mg-r-4 txt-color-gray'>Cần thanh toán: </span>
											<span>
												{item.totalUnpaid ? formatMoneyByUnit(item.totalUnpaid) : '---'}
											</span>
										</div>

										<div className='justify-start mg-t-4'>
											<span className='mg-r-4 txt-color-gray'>Khách hàng: </span>
											<span> {item.username ? item.username : '---'}</span>
										</div>

										<Row align='middle' className='mg-t-8 bg-color-pink px-10 py-10 rad-8'>
											{item.services.length > 0 && (
												<div className='mg-r-18 flex'>
													<span className='txt-color-view fsz-14 line-h-20 whitespace mg-r-4'>
														{/* {`${t('shipments.services')}:`} */}
														Dịch vụ:{' '}
													</span>
													{services.length > 0
														? services.map((item: ServiceInterface, idx: number) => {
																let colorService = ''
																if (item.needApprove) {
																	if (
																		item.approved === null ||
																		item.approved === undefined
																	) {
																		colorService = 'services-pending'
																	} else if (item.approved === false) {
																		colorService = 'services-reject'
																	}
																}
																return (
																	<span className='items-center' key={idx}>
																		<span
																			className={`mg-r-4 text text-nowrap line-h-20 ${colorService}`}
																		>{`${item.name}`}</span>
																		{idx !== services.length - 1 && (
																			<span className='mg-r-4'>|</span>
																		)}
																	</span>
																)
														  })
														: '---'}
												</div>
											)}
										</Row>

										<div className='flex justify-between mg-t-8'>
											<Row align='middle' className='mg-bt-6'>
												<div className={'dpl-flex align-items-center fsz-14 line-h-22'}>
													<Avatar
														size={20}
														src={provider?.logo}
														shape={'circle'}
														className='bd-none-impt mg-r-4'
													/>
													<span className='txt-color-black fsz-14 line-h-22'>
														{provider?.code}
													</span>
													<Divider
														type={'vertical'}
														className={'mx-10 bd-color-black h-16 line-h-22 mx-4'}
													/>
													<span className={'robotoregular fsz-14 line-h-22'}>
														{provider?.name}
													</span>
												</div>
											</Row>

											<Row align='middle'>
												<span className='fsz-14 line-h-22'>
													<span className='fsz-14 line-h-22 txt-color-gray'>
														{item.timestamp ? formatDateTime1(item.timestamp) : '---'}{' '}
													</span>
												</span>
											</Row>
										</div>
									</>
								}
							/>
						</List.Item>
					)
				}}
			/>
		</>
	)
}

export default ShipmentList

