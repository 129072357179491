import { Button, Grid, Tooltip } from 'antd'
import React from 'react'
// import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DATA_TABLE_LAYOUT, DATA_TABLE_LIST } from '../../../../src/Util/Constants'
interface HeaderListProps {
	total: number
	typeDataTable: string
	setTypeDataTable: (val: string) => void
}
const { useBreakpoint } = Grid

const HeaderList: React.FC<HeaderListProps> = (props) => {
	const { total, typeDataTable, setTypeDataTable } = props

	const navigate = useNavigate()
	return (
		<div className='flex justify-between bg-white header-list '>
			<div className='flex items-center'>
				<h3 className='txt-color-black mg-t-8 mg-r-10  font-size-16 line-h-24 '>Danh Sách Đơn Ký Gửi</h3>
				<span
					className={`${ 
						total > 0 ? 'bg-color-badge txt-color-white' : 'bg-color-gray-badge txt-color-gray4'
					} rad-20  font-size-12 line-h-20 px-8 mg-r-20`}
				>
					{total}
				</span>
				<Tooltip
					title={
						<span className='robotoregular font-size-12 txt-color-white line-h-20'>
							{/* {t('shipments.listLayout')} */}
							Xem dưới dạng lưới
						</span>
					}
					color='#FBB739'
				>
					<span
						className={`${
							typeDataTable === DATA_TABLE_LIST ? 'rad-4 bg-color-view' : ''
						} cursor-pointer mg-r-10 line-h-16  flex justify-center items-center pd-4 headerList-select-view `}
					>
						<i
							className={`${
								typeDataTable === DATA_TABLE_LIST ? 'txt-color-view' : ''
							} font-size-16 line-h-16  fa-light  fa-table-cells cursor-pointer txt-color-gray2`}
							onClick={() => setTypeDataTable(DATA_TABLE_LIST)}
						></i>
					</span>
				</Tooltip>
				<Tooltip
					title={
						<span className='robotoregular font-size-12 txt-color-white line-h-20'>
							{/* {t('shipments.tableLayout')} */}
							Xem dưới dạng bảng
						</span>
					}
					color='#FBB739'
				>
					<span
						className={`${
							typeDataTable === DATA_TABLE_LAYOUT ? ' bg-color-view rad-4' : ''
						}  cursor-pointer line-h-12  justify-center items-center pd-4 headerList-select-view `}
					>
						<i
							className={`font-size-16 fa-light  fa-table-list cursor-pointer txt-color-gray2 ${
								typeDataTable === DATA_TABLE_LAYOUT ? 'txt-color-view' : ''
							}`}
							onClick={() => setTypeDataTable(DATA_TABLE_LAYOUT)}
						></i>
					</span>
				</Tooltip>
			</div>
		</div>
	)
}

export default HeaderList

