export interface ShipmentsCriteria {
	page?: number | null;
	size?: number | null;
	sort?: string;
	providerOrderCode?: string | null;
	code?: string | null;
	originalReceipts?: string | null;
	merchantName?: string | null;
	provider?: string | null;
	providerUsername?: string | null;
	providerShipmentCode?: string | null;
	waybills?: string | null;
	username?: string | null;
	statuses?: string[] | null;
	peerPaymentCode?: string | null;
	peerPaymentStatus?: string | null;
	peerPaymentUsername?: string | null;
	peerPaymentProvider?: string | null;
	marketplaces?: string[] | null;
	timestampTo?: string | null;
	timestampFrom?: string | null;
}

export const ShipmentsCriteriaDefault: ShipmentsCriteria = {
	page: 0,
	size: 1,
	sort: '',
	code: '',
	merchantName: '',
	originalReceipts: '',
	providerShipmentCode: '',
	waybills: '',
	providerOrderCode: '',
	provider: '',
	providerUsername: '',
	peerPaymentCode: '',
	peerPaymentStatus: '',
	peerPaymentUsername: '',
	peerPaymentProvider: '',
	username: '',
	statuses: [],
	marketplaces: [],
	timestampTo: '',
	timestampFrom: '',
}

