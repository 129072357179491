import { Avatar, Divider, Table, Tooltip } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
// import { t } from 'i18next'
import lodash from 'lodash'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ProviderInterface from '../../../Interface/ProviderInterface'
import StatusInterface from '../../../Interface/StatusInterface'
// import TruncateText from '../../../../components/TruncateText'
// import { formatDateTime } from '../../../../core/helpers/date-time'
// import { formatMoneyByUnit } from '../../../core/helpers/string'
// import { ProviderInterface } from '../../../Interface/AccountBalanceInterface'
// import { ConnectionInterface } from '../../../Interface/ConnectionInterface'
// import ShipmentsFilterInterface from '../../../Interface/ShipmentFilterInterface'
// import ShipmentInterface from '../../../Interface/ShipmentInterface'
// import ShipmentStatusInterface from '../../../Interface/ShipmentStatusInterface'
// import ShipmentImageDefault from '../../../resources/images/shipment-ubox-default.png'
import { formatDateTime1, formatMoneyByUnit } from '../../../Util/string'
// import RowExtended from '../../Orders/List/RowExtended'
import HeaderList from './HeaderList'
import RowExtended from './RowExtend'

interface DataTableProps {
	// isSkeleton: boolean
	// loading: boolean
	total: number
	// filter: any | {}
	// accounts: any[]
	statuses: any[]
	providers: any[]
	data: any[]
	typeDataTable: string
	handleTypeTableData: (val: string) => void
}
const DataTable: React.FC<DataTableProps> = (props) => {
	const {
		handleTypeTableData,
		total,
		data,
		// accounts,
		statuses,
		providers,
		typeDataTable,
	} = props
	const [expandedRows] = useState<Array<any>>([])

	const columns = [
		{
			title: (
				<span className='robotoregular font-size-14 line-h-22 txt-color-gray'>
					{/* {t('shipment.codeTitle')} */}
					Đơn hàng
				</span>
			),

			width: '40%',
			render: (item: any) => {
				return (
					<div className='flex items-center font-size-14'>
						<Avatar
							src={item.image ? item?.image : ''}
							shape='square'
							size={80}
							className='bd-none-impt rad-6 mg-r-20'
						/>
						<div className='flex flex-col'>
							<Link to={`/shipments/${item.code}`} className='mg-r-16 mg-bt-10 '>
								<Paragraph
									className='robotomedium mg-0 txt-color-view font-size-14 line-h-22 whitespace'
									copyable={{
										text: item.code,
										icon: [
											<Tooltip
												title={
													<span className='robotoregular fsz-12 txt-color-black line-h-20'>
														Copy
													</span>
												}
												color='#FBB739'
											>
												<i className='far fa-copy txt-color-black line-h-14 w-12'></i>
											</Tooltip>,
											<Tooltip
												title={
													<span className='robotoregular fsz-12 txt-color-black line-h-20'>
														Copied
													</span>
												}
												color='#FBB739'
											>
												<i className='far fa-check fsz-12 line-h-14 w-12'></i>
											</Tooltip>,
										],
										tooltips: [false, false],
									}}
								>
									{`#${item.code}`}
								</Paragraph>
							</Link>
							<div>
								<Tooltip
									color='#FBB739'
									title={
										<span className='robotoregular fsz-12 txt-color-black line-h-20'>
											{/* {t('shipments.quantifyTooltip')} */}
										</span>
									}
								>
									<span className='mg-r-3'>{`${
										item.orderedQuantity ? item.orderedQuantity : '---'
									} / ${item.receivedQuantity ? item.receivedQuantity : '---'}`}</span>
								</Tooltip>
							</div>
						</div>
					</div>
				)
			},
		},
		{
			title: (
				<span className='robotoregular font-size-14 line-h-22 txt-color-gray'>
					{/* {t('shipments.createdAt')} */}
					Nhà cung cấp
				</span>
			),
			align: 'left' as 'left',
			width: '20%',
			render: (item: any) => {
				const provider = providers.find((prod: ProviderInterface) => prod.code === item.provider)
				return (
					<>
						<div className={' font-size-14  mg-bt-10'}>
							<Avatar size={20} src={provider?.logo} shape={'circle'} className='bd-none-impt mg-r-4' />
							<span className='txt-color-black font-size-14 line-h-22'>{provider?.code}</span>
							<Divider type={'vertical'} className={'mx-10 bd-color-black h-16 line-h-22 mx-4'} />
							<span className={'robotoregular font-size-14 line-h-22'}>{provider?.name}</span>
						</div>
						<div>
							<span className='font-size-14 txt-color-gray'>Khách hàng: </span>
							<span className='font-size-14'>{item.username}</span>
						</div>
					</>
				)
			},
		},
		{
			title: (
				<span className='robotoregular font-size-14 line-h-22 txt-color-gray'>
					{/* {t('shipments.financeTotalFees')} */}
					Cần thanh toán
				</span>
			),
			align: 'center' as 'center',
			width: '20%',
			render: (item: any) => {
				return (
					<span className='font-size-14 txt-color-red'>
						{item.totalUnpaid ? formatMoneyByUnit(item.totalUnpaid) : '---'}
					</span>
				)
			},
		},
		{
			title: (
				<span className='robotoregular font-size-14 line-h-22 txt-color-gray'>
					{/* {t('shipments.statusOfShipment')} */}
					Trạng thái
				</span>
			),
			align: 'right' as 'right',
			width: '25%',
			render: (item: any) => {
				const status = statuses.find((stt: StatusInterface) => stt.code === item.status)
				return (
					<>
						<span
							className='line-h-22 rad-25 txt-color-white px-16 py-6 txt-capitalize font-size-14'
							style={{
								backgroundColor: `${status?.color ? status.color : '#0082E6'}`,
							}}
						>
							{status?.name}
						</span>
						<div className=' mg-t-12'>
							<span className='font-size-14 line-h-22'>
								{item.timestamp ? formatDateTime1(item.timestamp) : '---'}{' '}
							</span>
						</div>
					</>
				)
			},
		},
	]
	const components = { body: { row: RowExtended } }

	return (
		<Table
			rowKey={(item: any) => item.id}
			columns={columns}
			dataSource={data}
			pagination={false}
			className='table-improved font-size-14'
			components={components}
			onRow={(item, index) => {
				const rowClassesByIdx = index! % 2 === 0 ? 'row-dark' : 'row-light'
				return { ...item, index, rowClassesByIdx }
			}}
			title={() => (
				<HeaderList
					total={total}
					typeDataTable={typeDataTable}
					setTypeDataTable={(val: string) => handleTypeTableData(val)}
				/>
			)}
			// components={}
			scroll={{ x: 526 }}
			rowClassName={(item, idx) => {
				const rowClassesActiveByExpanded = lodash.includes(expandedRows, item.id)
					? 'active-row cursor-pointer'
					: 'data-row cursor-pointer'
				const rowClassesByIdx = idx % 2 === 0 ? 'row-dark' : 'row-light'
				return `${rowClassesActiveByExpanded} ${rowClassesByIdx}`
			}}
		/>
	)
}

export default DataTable

