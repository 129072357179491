import lodash from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { CategoryApi } from '../../Api/CategoryApi'
import MarketPalaceCommand from '../../Interface/MarketPalaceInterface'
import ProviderCommand from '../../Interface/ProviderInterface'
import StatusCommand from '../../Interface/StatusInterface'
import DefaultLayout from '../../Component/Layout/Default'
import { getCleanFormFilter } from '../../Util/string'
import Filter from './Filter'
import { trans } from '../../resources/localization/Localization'
import '../../resources/scss/screens/_shipments.scss'
import ShipmentItems from './Data'
import { ShipmentsApi } from '../../Api/ShipmentsApi'
import { ShipmentsCriteria } from '../../Criteria/ShipmentsCriteria'

const Shipments = (props: any) => {
	const [shipments, setShipments] = useState<Array<ShipmentsCriteria>>([])
	const [searchParams, setSearchParams] = useSearchParams()
	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(Number(searchParams.get('page')))
	const [pageSize, setPagesize] = useState(Number(searchParams.get('size')))
	const [providers, setProviders] = useState<Array<ProviderCommand>>([])
	const [shipmentStatus, setShipmentStatus] = useState<Array<StatusCommand>>([])
	const [marketPalaces, setMarketplaces] = useState<Array<MarketPalaceCommand>>([])
	const [filter, setFilter] = useState<ShipmentsCriteria>({})
	const [loading, setLoading] = useState(false)
	const [loadingStatuses, setLoadingStatuses] = useState(false)
	const [loadingMarketPalaces, setLoadingMarketPalaces] = useState(false)

	const getShipments = useCallback(async () => {
		const merchantName = searchParams.get('merchantName')
		const code = searchParams.get('code')
		const waybills = searchParams.get('waybills')
		const originalReceipts = searchParams.get('originalReceipts')
		const username = searchParams.get('username')
		const statuses = searchParams.get('statuses')
		// const marketplaces = searchParams.get('marketplaces')
		const timestampTo = searchParams.get('timestampTo')
		const timestampFrom = searchParams.get('timestampFrom')
		const page = searchParams.get('page')
		const size = searchParams.get('size')
		const temp = {
			page,
			size,
			code,
			waybills,
			originalReceipts,
			merchantName,
			username,
			statuses,
			// marketplaces,
			timestampTo,
			timestampFrom,
		}
		setFilter((prevState) => ({ ...prevState, ...getCleanFormFilter(temp) }))
		setLoading(true)
		try {
			const response = await ShipmentsApi.filter(getCleanFormFilter(temp))
			const currentPage = lodash.get(response, 'headers.x-page-number')
			const total = lodash.get(response, 'headers.x-total-count')
			setShipments(response.data)
			setPage(parseInt(currentPage) + 1)
			setTotal(parseInt(total))
			setPagesize(parseInt(lodash.get(response, 'headers.x-page-size')))
			setLoading(false)
		} catch (err) {
			setLoading(false)
		}
	}, [searchParams])

	const getProviders = useCallback(async () => {
		try {
			const response = await CategoryApi.getProviders()
			setProviders(response.data)
		} catch (err) {}
	}, [])

	const getMarketPalaces = useCallback(async () => {
		setLoadingMarketPalaces(true)
		try {
			const response = await CategoryApi.getMarketPalaces()
			setMarketplaces(response.data)
			setLoadingMarketPalaces(false)
		} catch (err) {
			setLoadingMarketPalaces(false)
		}
	}, [])

	const getStatuses = useCallback(async () => {
		setLoadingStatuses(true)
		try {
			const response = await CategoryApi.getStatuses()
			setShipmentStatus(response.data)
			setLoadingStatuses(false)
		} catch (err) {
			setLoadingStatuses(false)
		}
	}, [])

	useEffect(() => {
		getShipments()
	}, [getShipments])

	useEffect(() => {
		getProviders()
		getStatuses()
		getMarketPalaces()
	}, [getStatuses, getMarketPalaces, getProviders])

	const handleFilter = (params: any) => {
		setFilter({ ...params })
		setSearchParams({ ...params })
	}

	
	return (
		<DefaultLayout title={trans('orders.list')} {...props}>
			<Filter
				handleFilter={handleFilter}
				statuses={shipmentStatus}
				marketPalaces={marketPalaces}
				setFilter={(obj: ShipmentsCriteria) => setFilter(obj)}
				filter={filter}
				loadingStatuses={loadingStatuses}
				loadingMarkets={loadingMarketPalaces}
			/>
			<ShipmentItems
				total={total}
				page={page}
				size={pageSize}
				data={shipments}
				providers={providers}
				statuses={shipmentStatus}
				handleFilter={handleFilter}
				setPage={(val: number) => setPage(val)}
				setPageSize={(val: number) => setPagesize(val)}
				filter={filter}
				loading={loading}
			/>
		</DefaultLayout>
	)
}

export default Shipments

