import React, { useState } from 'react'
import { DATA_TABLE_LAYOUT, DATA_TABLE_LIST } from '../../../Util/Constants'
import { getCleanFormFilter } from '../../../Util/string'
import DataTable from './DataTable'

// import M24Pagination from '../../../components/Pagination/M24Pagination'
// import { DATA_TABLE_LAYOUT, DATA_TABLE_LIST } from '../../../core/config'
// import { ProviderInterface } from '../../../Interface/AccountBalanceInterface'
// import { ConnectionInterface } from '../../../Interface/ConnectionInterface'
// import MarketPalaceInterface from '../../../Interface/MarketPalaceInterface'
// import ShipmentsFilterInterface from '../../../Interface/ShipmentFilterInterface'
// import ShipmentInterface from '../../../Interface/ShipmentInterface'
// import ShipmentStatusInterface from '../../../Interface/ShipmentStatusInterface'
// import { getCleanFormFilter } from '../../../utils/StringUtils'
// import DataTable from './DataTable'
import ShipmentList from './List'
import { Pagination } from 'antd'
import StatusInterface from '../../../Interface/StatusInterface'
import ProviderInterface from '../../../Interface/ProviderInterface'

interface ShipmentItemsProps {
	data: any
	// accounts: ConnectionInterface[]
	statuses: Array<StatusInterface>
	loading: boolean
	page: number
	size: number
	total: number
	// isSkeleton: boolean
	filter: any | {}
	// marketPalaces: MarketPalaceInterface[]
	providers: ProviderInterface[]
	// getData: () => void
	handleFilter: (params: any | {}) => void
	setPage: (val: number) => void
	setPageSize: (val: number) => void
}

const ShipmentItems: React.FC<ShipmentItemsProps> = (props) => {
	const {
		data,
		//   accounts,
		   statuses,
		loading,
		filter,
		page,
		size,
		total,
		handleFilter,
		providers,
		setPage,
		setPageSize
		// isSkeleton,
	} = props

	const [typeDataTable, setTypeDataTable] = useState(DATA_TABLE_LIST)
	
	// const pageChangeHandler = (filter: any) => {
	// 	const { page, size } = filter
	// 	handleFilter(
	// 		getCleanFormFilter(
	// 			{
	// 				...filter,
	// 				page: (page - 1).toString(),
	// 				size: size.toString(),
	// 			},
	// 			[],
	// 			['pageSize']
	// 		)
	// 	)
	// }

	const onChangePage = (page: number, pageSize: number) => {
		setPage(page)
		setPageSize(pageSize)
		handleFilter(
			getCleanFormFilter(
				{
					...filter,
					page: (page - 1).toString(),
					size: pageSize.toString(),
				},
				[],
				['pageSize']
			)
		)
	}
	// const showTotal = (total: number, range: number[]) =>
	// 	`${range[0]}-${range[1]} ${trans('orders.inRange')} ${total} ${trans('orders.record')}`


	return (
		<div>
			{typeDataTable === DATA_TABLE_LIST && (
				<ShipmentList
					typeDataTable ={typeDataTable}
					// isSkeleton={isSkeleton}
					handleTypeTableData={(val: string) => setTypeDataTable(val)}
					total={total}
					// filter={filter}
					// accounts={accounts}
					statuses={statuses}
					providers={providers}
					data={data}
					// loading={loading}
				/>
			)}
			{typeDataTable === DATA_TABLE_LAYOUT && (
				<DataTable
				typeDataTable={typeDataTable}
				// isSkeleton={isSkeleton}
				handleTypeTableData={(val: string) => setTypeDataTable(val)}
				total={total}
				// filter={filter}
				// accounts={accounts}
				statuses={statuses}
				providers={providers}
				data={data}
				// loading={loading}
				/>
			)}
			<div className='flex justify-end mg-t-10'>
				<Pagination
					total={total}
					// showTotal={showTotal}
					pageSize={size}
					current={page}
					onChange={onChangePage}
					className='shipments_pagination'
					pageSizeOptions={['10', '20', '25', '50', '100']}
					// locale={{ items_per_page: `/ ${trans('orders.page')}` }}
					showSizeChanger={true}
					// className='pagination-separate'
				/>
			</div>
		</div>
	)
}

export default ShipmentItems

