import lodash from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { CategoryApi } from '../../Api/CategoryApi'
import { OrdersApi } from '../../Api/OrdersApi'
import MarketPalaceCommand from '../../Interface/MarketPalaceInterface'
import ProviderCommand from '../../Interface/ProviderInterface'
import StatusCommand from '../../Interface/StatusInterface'
import DefaultLayout from '../../Component/Layout/Default'
import { OrdersCriteria } from '../../Criteria/OrdersCriteria'
import { getCleanFormFilter } from '../../Util/string'
import Filter from './Filter'
import List from './List'
import { trans } from '../../resources/localization/Localization'

const Orders = (props: any) => {
	const [orders, setOrders] = useState<Array<OrdersCriteria>>([])
	const [searchParams, setSearchParams] = useSearchParams()
	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(Number(searchParams.get('page')))
	const [pageSize, setPagesize] = useState(Number(searchParams.get('size')))
	const [providers, setProviders] = useState<Array<ProviderCommand>>([])
	const [orderStatuses, setOrderStatuses] = useState<Array<StatusCommand>>([])
	const [marketPalaces, setMarketplaces] = useState<Array<MarketPalaceCommand>>([])
	const [filter, setFilter] = useState<OrdersCriteria>({})
	const [loading, setLoading] = useState(false)
	const [loadingStatuses, setLoadingStatuses] = useState(false)
	const [loadingMarketPalaces, setLoadingMarketPalaces] = useState(false)

	const getOrders = useCallback(async () => {
		const providerUsername = searchParams.get('providerUsername')
		const providerOrderCode = searchParams.get('providerOrderCode')
		const provider = searchParams.get('provider')
		const username = searchParams.get('username')
		const statuses = searchParams.get('statuses')
		const marketplaces = searchParams.get('marketplaces')
		const timestampTo = searchParams.get('timestampTo')
		const timestampFrom = searchParams.get('timestampFrom')
		const page = searchParams.get('page')
		const size = searchParams.get('size')
		const temp = {
			page,
			size,
			providerUsername,
			providerOrderCode,
			provider,
			username,
			statuses,
			marketplaces,
			timestampFrom,
			timestampTo,
		}
		setFilter((prevState) => ({ ...prevState, ...getCleanFormFilter(temp) }))
		setLoading(true)
		try {
			const response = await OrdersApi.filter(getCleanFormFilter(temp))
			const currentPage = lodash.get(response, 'headers.x-page-number')
			const total = lodash.get(response, 'headers.x-total-count')
			setOrders(response.data)
			setPage(parseInt(currentPage) + 1)
			setTotal(parseInt(total))
			setPagesize(parseInt(lodash.get(response, 'headers.x-page-size')))
			setLoading(false)
		} catch (err) {
			setLoading(false)
		}
	}, [searchParams])

	const getProviders = useCallback(async () => {
		try {
			const response = await CategoryApi.getProviders()
			setProviders(response.data)
		} catch (err) {}
	}, [])

	const getMarketPalaces = useCallback(async () => {
		setLoadingMarketPalaces(true)
		try {
			const response = await CategoryApi.getMarketPalaces()
			setMarketplaces(response.data)
			setLoadingMarketPalaces(false)
		} catch (err) {
			setLoadingMarketPalaces(false)
		}
	}, [])

	const getStatuses = useCallback(async () => {
		setLoadingStatuses(true)
		try {
			const response = await CategoryApi.getStatuses()
			setOrderStatuses(response.data)
			setLoadingStatuses(false)
		} catch (err) {
			setLoadingStatuses(false)
		}
	}, [])

	useEffect(() => {
		getOrders()
	}, [getOrders])

	useEffect(() => {
		getProviders()
		getStatuses()
		getMarketPalaces()
	}, [getStatuses, getMarketPalaces, getProviders])

	const handleFilter = (params: any) => {
		setFilter({ ...params })
		setSearchParams({ ...params })
	}

	return (
		<DefaultLayout title={trans('orders.list')} {...props}>
			<Filter
				handleFilter={handleFilter}
				statuses={orderStatuses}
				marketPalaces={marketPalaces}
				setFilter={(obj: OrdersCriteria) => setFilter(obj)}
				filter={filter}
				loadingStatuses={loadingStatuses}
				loadingMarkets={loadingMarketPalaces}
			/>
			<List
				total={total}
				page={page}
				size={pageSize}
				items={orders}
				providers={providers}
				statuses={orderStatuses}
				handleFilter={handleFilter}
				setPage={(val: number) => setPage(val)}
				setPageSize={(val: number) => setPagesize(val)}
				filter={filter}
				loading={loading}
			/>
		</DefaultLayout>
	)
}

export default Orders

