import { RedoOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import lodash, { identity, isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SupplierApi } from '../../Api/SupplierApi'
import { ShipmentsCriteria, ShipmentsCriteriaDefault } from '../../Criteria/ShipmentsCriteria'
import ConnectionInterface from '../../Interface/ConnectionInterface'
import MarketPalaceInterface from '../../Interface/MarketPalaceInterface'
import ProfileInterface from '../../Interface/ProfileInterface'
import StatusInterface from '../../Interface/StatusInterface'
import { trans } from '../../resources/localization/Localization'
import { SELECT_CONNECTION, SELECT_PROFILE, TIME_STAMP_FROM, TIME_STAMP_TO } from '../../Util/Constants'
import useWindowDimensions from '../../Util/dimention'
const { Item } = Form
const { Option } = Select
const { RangePicker } = DatePicker
const beginDateFormat = 'YYYY-MM-DD HH:mm:00'
const endDateFormat = 'YYYY-MM-DD HH:mm:59'
interface FilterProps {
	statuses?: Array<StatusInterface>
	marketPalaces?: Array<MarketPalaceInterface>
	filter: ShipmentsCriteria
	loadingStatuses: boolean
	loadingMarkets: boolean
	setFilter: (obj: ShipmentsCriteria | {}) => void
	handleFilter: (params: ShipmentsCriteria) => void
}
const Filter: React.FC<FilterProps> = (props) => {
	const { statuses, handleFilter, setFilter, filter } = props
	const [form] = Form.useForm()
	const [searchParams] = useSearchParams()
	const [checkedStatuses, setCheckedStatuses] = useState<string[]>(searchParams.get('statuses')?.split(',') || [])
	// const [checkedMarketPalaces, setCheckedMarketPalaces] = useState<string[]>(
	// 	searchParams.get('marketplaces')?.split(',') || []
	// )
	const [showFilter, setShowFilter] = useState(true)
	const [usernameSelected, setUsernameSelected] = useState<string | undefined>()
	const [connectionSelected, setConnectionSelected] = useState<string | undefined>()
	const [connectionsFilter, setConnectionsFilter] = useState<Array<ConnectionInterface>>([])
	const [profilesFiltered, setProfilesFiltered] = useState<Array<ProfileInterface>>([])
	const [searchExpand, setSearchExpand] = useState(true)

	const { width } = useWindowDimensions()

	// useEffect(() => {
	// 	// const fetchData = async () => {
	// 	// 	if (searchParams.get('providerUsername') && searchParams.get('providerUsername')!.length >= 3) {
	// 	// 		const response = await SupplierApi.getAllConnections(searchParams.get('providerUsername')!)
	// 	// 		const conn = response.data.find(
	// 	// 			(conn: ConnectionInterface) =>
	// 	// 				conn.providerUsername === searchParams.get('providerUsername') &&
	// 	// 				conn.provider.code === searchParams.get('provider')
	// 	// 		)
	// 	// 		if (conn) setConnectionSelected(conn.id)
	// 	// 		setConnectionsFilter(response.data)
	// 	// 	}
	// 	// 	if (searchParams.get('username') && searchParams.get('username')!.length >= 3) {
	// 	// 		const response = await SupplierApi.getProfiles(searchParams.get('username')!)
	// 	// 		const profile = response.data.find(
	// 	// 			(profile: ProfileInterface) => profile.username === searchParams.get('username')
	// 	// 		)
	// 	// 		if (profile) setUsernameSelected(profile.id)
	// 	// 		setProfilesFiltered(response.data)
	// 	// 	}
	// 	// }
	// 	// fetchData()
	// }, [searchParams])

	useEffect(() => {
		const condition =
			searchParams.get('code') === null &&
			searchParams.get('waybills') === null &&
			searchParams.get('originalReceipts') === null &&
			searchParams.get('merchantName') === null &&
			searchParams.get('timestampFrom') === null &&
			searchParams.get('timestampTo') === null &&
			searchParams.get('page') === null &&
			searchParams.get('size') === null &&
			searchParams.get('statuses') === null
		if (condition) {
			clearFilter()
		} else {
			return
		}

		// searchParams.get('usernameSelected')
	}, [searchParams])

	const handleChangeStatuses = (key: string, selected: boolean) => {
		if (selected) {
			setCheckedStatuses([...checkedStatuses, key])
			setFilter((prevState: ShipmentsCriteria) => ({
				...prevState,
				statuses: [...checkedStatuses, key].join(),
			}))
		} else {
			setCheckedStatuses(checkedStatuses.filter((stt: string) => stt !== key))
			setFilter((prevState: ShipmentsCriteria) => ({
				...prevState,
				statuses: [...checkedStatuses.filter((stt: string) => stt !== key)].join(),
			}))
		}
	}

	const onFinish = () => {
		handleFilter({ ...lodash.pickBy(filter, (val) => !isEmpty(val) && identity(val)), page: 0 })
	}

	const clearFilter = () => {
		form.resetFields()
		setCheckedStatuses([])
		setUsernameSelected(undefined)
		handleFilter(lodash.pickBy(ShipmentsCriteriaDefault, (val) => !isEmpty(val) && identity(val)))
	}

	// const handleChangeMarketplaces = (code: string, checked: boolean) => {
	// 	if (checked) {
	// 		setCheckedMarketPalaces([...checkedMarketPalaces, code])
	// 		setFilter((prevState: ShipmentsCriteria) => ({
	// 			...prevState,
	// 			marketplaces: [...checkedMarketPalaces, code].join(),
	// 		}))
	// 	} else {
	// 		setCheckedMarketPalaces(checkedMarketPalaces.filter((stt: string) => stt !== code))
	// 		setFilter((prevState: ShipmentsCriteria) => ({
	// 			...prevState,
	// 			marketplaces: [...checkedMarketPalaces.filter((stt: string) => stt !== code)],
	// 		}))
	// 	}
	// }

	const handleChangeValue = (key: string, value: string) => {
		if (key === SELECT_PROFILE) {
			setUsernameSelected(value)
			const username = lodash.get(
				profilesFiltered?.find((x: ProfileInterface) => x.id === value),
				'username'
			)
			setFilter({ ...filter, username })
		}
		if (key === SELECT_CONNECTION) {
			const connection = connectionsFilter?.find((x: ConnectionInterface) => x.id === value)
			const providerUsername = lodash.get(connection, 'providerUsername')
			const provider = lodash.get(connection, 'provider.code')
			setConnectionSelected(value)
			setFilter({ ...filter, provider, providerUsername })
		}
	}

	const handleAutoComplete = async (key: string, value: string | null) => {
		if (value && value.length >= 3) {
			try {
				if (key === SELECT_CONNECTION) {
					const response = await SupplierApi.getAllConnections(value)
					setConnectionsFilter(response.data)
				}
				if (key === SELECT_PROFILE) {
					const response = await SupplierApi.getProfiles(value)
					setProfilesFiltered(response.data)
				}
			} catch (err) {}
		} else {
			if (key === SELECT_CONNECTION) {
				setConnectionsFilter([])
			}
			if (key === SELECT_PROFILE) {
				setProfilesFiltered([])
			}
		}
	}

	const handleChangeDate = (dates: any, dateString: string[]) => {
		setFilter({
			...filter,
			[TIME_STAMP_FROM]: dates ? moment(dates[0].format(beginDateFormat)).toISOString() : null,
			[TIME_STAMP_TO]: dates ? moment(dates[1].format(endDateFormat)).toISOString() : null,
		})
	}

	const handleClear = (key: string) => {
		if (key === SELECT_CONNECTION) {
			setFilter({ ...lodash.omit(filter, ['provider', 'providerUsername']) })
			setConnectionsFilter([])
		}
		if (key === SELECT_PROFILE) {
			setFilter({ ...lodash.omit(filter, ['username']) })
			setProfilesFiltered([])
		}
	}

	return (
		<Row className='shipments-filter mg-0 mg-bt-12'>
			<Row
				className={`shipments-filter__title ${showFilter ? 'mg-bt-20' : ''} flex justify-between items-center `}
				align='middle'
			>
				<Col className='title'>
					{/* <h3>{trans('shipments.search')}</h3> */}
					<h3>Tìm kiếm đơn hàng</h3>
				</Col>
				<Col>
					<i
						className={`cursor-pointer fa-solid fa-angle-${showFilter ? 'up' : 'down'}`}
						onClick={() => setShowFilter(!showFilter)}
					/>
				</Col>
			</Row>
			{showFilter && (
				<>
					<div className={`shipments-filter__statuses mg-bt-20 rad-6`}>
						{statuses?.map(({ code, name }: StatusInterface) => {
							const selected = checkedStatuses && checkedStatuses.includes(code) ? true : false
							return (
								<span
									className={selected ? 'status active' : 'status'}
									key={code}
									onClick={() => {
										handleChangeStatuses(code, !selected)
									}}
								>
									{name}
								</span>
							)
						})}
					</div>
					{searchExpand && (
						<>
							<Form
								name='order-filter'
								onFinish={onFinish}
								className='shipments-filter__form mg-bt-10'
								form={form}
							>
								<Row gutter={[32, 16]} align='middle' className='shipments-filter__form-input'>
									<Col xxl={6} md={8} sm={24} xs={24}>
										<Input
											className={` form-input ${
												filter.code !== undefined ? 'input-has-value' : ''
											}`}
											placeholder=''
											onChange={(e) =>
												setFilter({
													...filter,
													code: e.target.value,
												})
											}
											value={filter.code ? filter.code : ''}
											allowClear
										/>
										<label className='robotoregular form-label text-grey-400'>
											{'Mã đơn hàng'}
										</label>
									</Col>

									<Col xxl={6} md={8} sm={24} xs={24}>
										<Input
											className={` form-input ${
												filter.waybills !== undefined ? 'input-has-value' : ''
											}`}
											placeholder=''
											onChange={(e) =>
												setFilter({
													...filter,
													waybills: e.target.value,
												})
											}
											value={filter.waybills ? filter.waybills : ''}
											allowClear
										/>
										<label className='robotoregular form-label text-grey-400'>{'Mã vận đơn'}</label>
									</Col>

									<Col xxl={6} md={8} sm={24} xs={24}>
										<Input
											className={` form-input ${
												filter.originalReceipts !== undefined ? 'input-has-value' : ''
											}`}
											placeholder=''
											onChange={(e) =>
												setFilter({
													...filter,
													originalReceipts: e.target.value,
												})
											}
											value={filter.originalReceipts ? filter.originalReceipts : ''}
											allowClear
										/>
										<label className='robotoregular form-label text-grey-400'>
											{'Mã hóa đơn gốc'}
										</label>
									</Col>

									<Col xxl={6} md={8} sm={24} xs={24}>
										{/* <Input
											// placeholder={trans('orders.code')}
											placeholder='Tên shop'
											onChange={(e) =>
												setFilter({
													...filter,
													merchantName: e.target.value,
												})
											}
											value={filter.merchantName ? filter.merchantName : ''}
											allowClear
										/> */}

										<Input
											className={` form-input ${
												filter.merchantName !== undefined ? 'input-has-value' : ''
											}`}
											// ${className ? className : ''} ${value !== '' ? 'input-has-value' : ''}
											placeholder=''
											onChange={(e) =>
												setFilter({
													...filter,
													merchantName: e.target.value,
												})
											}
											value={filter.merchantName ? filter.merchantName : ''}
											allowClear
										/>
										<label className='robotoregular form-label text-grey-400'>{'Tên shop'}</label>
									</Col>

									<Col xxl={6} md={8} sm={24} xs={24} className='shipments-filter__form'>
										<RangePicker
											value={[
												filter.timestampTo ? moment(filter.timestampTo) : null,
												filter.timestampFrom ? moment(filter.timestampFrom) : null,
											]}
											placeholder={[
												// trans('orders.createdAtPlaceholder'),
												// trans('orders.createdAtPlaceholder'),
												'	Từ ngày',
												'Đến ngày',
											]}
											format={'DD-MM-YYYY HH:mm'}
											suffixIcon={<i className='far fa-calendar-alt' />}
											onChange={(values: any, dateString: string[]) =>
												handleChangeDate(values, dateString)
											}
											showTime
											className={` form-input width100pc rad-8`}
										/>
									</Col>

									<Col xxl={6} md={8} sm={24} xs={24}>
										<Select
											value={usernameSelected}
											className={` form-input ${
												filter.merchantName !== undefined ? 'input-has-value' : ''
											}`}
											allowClear={true}
											showSearch
											filterOption={false}
											onSearch={lodash.debounce(
												(val) => handleAutoComplete(SELECT_PROFILE, val),
												1000
											)}
											onChange={(value) => {
												handleChangeValue(SELECT_PROFILE, value)
											}}
											onClear={() => handleClear(SELECT_PROFILE)}
											style={{ width: '100%' }}
											notFoundContent={null}
										>
											{profilesFiltered?.map((profile: ProfileInterface) => (
												<Option key={profile.id} value={profile.id}>{`${lodash.get(
													profile,
													'name'
												)} (${lodash.get(profile, 'username')})`}</Option>
											))}
										</Select>
										<label className='robotoregular form-label text-grey-400'>{'Khách hàng'}</label>
									</Col>
								</Row>
							</Form>
						</>
					)}
					<Row align='middle' justify='space-between' className='actions'>
						<Col span={12}>
							<span
								className='text cursor-pointer mg-r-7'
								onClick={() => setSearchExpand(!searchExpand)}
							>{`${searchExpand ? trans('orders.filterCollapse') : trans('orders.filterOpen')}`}</span>
							<i
								className={`cursor-pointer fa-solid fa-angle-${searchExpand ? 'up' : 'down'}`}
								onClick={() => setSearchExpand(!searchExpand)}
							/>
						</Col>
						{showFilter && (
							<Col span={12} className={'actions-btn'}>
								{!lodash.isEmpty(
									lodash.pickBy(filter, (val: any) => !isEmpty(val) && identity(val))
								) && (
									<Button className='clear' onClick={clearFilter} icon={<RedoOutlined />}>
										<span className='mg-0 clean-filter'>{trans('orders.cleanFilter')}</span>
									</Button>
								)}
								<Button
									className='btn btn-primary'
									type='primary'
									htmlType='submit'
									form='order-filter'
								>
									{trans('orders.search')}
								</Button>
							</Col>
						)}
					</Row>
				</>
			)}
		</Row>
	)
}

export default Filter

